import Loader from "@/components/Loader";
import NavBar from "@/components/NavBar";
import NumberStepper from "@/components/NumberStepper";
import activityModel from "@/models/activity.model";
import { useReactive, useRequest } from "ahooks";
import { useParams } from "react-router-dom";
import { Button, Form, Image, Toast } from "react-vant";
import styles from "./index.module.css";

export default () => {
  const params = useParams();
  const [form] = Form.useForm();
  const num = Form.useWatch("num", form);
  const state = useReactive({
    planId: 0,
  });
  const { data, refreshAsync } = useRequest(
    () => {
      return activityModel.detail(Number(params.id)).then((res) => res.data);
    },
    {
      ready: !!params.id,
      refreshDeps: [params.id],
      onSuccess: (data) => {
        form?.setFieldValue("num", data?.perLimit ?? 1);
      },
    }
  );
  const onFinish = async (data: any) => {
    const update = Toast({
      type: "loading",
      message: "正在提交...",
      forbidClick: true,
      duration: 0,
    });
    const res = await activityModel.order({
      id: params.id,
      planId: state.planId,
      ...data,
    });
    if (res?.code === 200) {
      await refreshAsync();
      update.config({
        message: res.data,
        forbidClick: true,
      });
    }
    setTimeout(() => {
      update.clear();
    }, 2000);
  };
  return (
    <div className={styles.page}>
      <NavBar title={data ? data.title : "活动详情"} />
      {!data ? (
        <Loader />
      ) : (
        <>
          <Image className={styles.resource} src={data.image} />
          <div className={styles.header}>
            <div className={styles.title}>{data.title}</div>
            <div className={styles.tips}>详见活动公告</div>
            <div className={styles.alert}>寄售中的数字资产无法参与合成</div>
          </div>
          <div className={styles.plans}>
            {data.burnPlans?.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  className={`${styles.plan} ${
                    state.planId === index ? styles.active : null
                  }`}
                  onClick={() => {
                    state.planId = index;
                  }}
                >
                  <div className={styles.title}>
                    方案{index + 1} {state.planId === index ? "(已选择)" : ""}
                  </div>
                  <div className={styles.list}>
                    {item.plans?.map((plan: any, idx: number) => {
                      const asset = JSON.parse(plan.asset);
                      return (
                        <div key={index + "-" + idx} className={styles.item}>
                          <div className={styles.left}>
                            <Image className={styles.image} src={asset.image} />
                          </div>
                          <div className={styles.right}>
                            <div className={styles.name}>{asset.title}</div>
                            <div className={styles.num}>数量: {plan.num}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          {/* <div className={styles.chain}>
            <Image
              className={styles.image}
              src={getAssetsUrl("images/shuwenlian.png")}
            />
          </div> */}
          <div className={styles.footer}>
            <Form className={styles.form} form={form} onFinish={onFinish}>
              <Form.Item
                name="num"
                noStyle
                rules={[
                  {
                    validator: async (_, value) => {
                      try {
                        if (!value) {
                          throw new Error("请选择活动参与数量");
                        }
                        if (value < 1) {
                          throw new Error("活动参与数量不能小于1");
                        }
                        if (value > data.perLimit) {
                          throw new Error(
                            `每次参与数量不能大于${data.perLimit}`
                          );
                        }
                      } catch (err: any) {
                        return Promise.reject(err);
                      }
                    },
                  },
                ]}
              >
                <NumberStepper title="参与次数" min={1} max={data.perLimit} />
              </Form.Item>
            </Form>
            {/* <div className={styles.tips}>
              {data.times > 0 ? `剩余${data.times}个` : "暂无"}活动资格
            </div> */}
            <Button
              className={styles.submit}
              type="primary"
              size="large"
              block
              disabled={!num || num <= 0}
              onClick={async () => {
                try {
                  await form.validateFields();
                  form.submit();
                } catch (e: any) {
                  if (e && e.errorFields) {
                    Toast({
                      message: e.errorFields[0]?.errors[0],
                      position: "top",
                    });
                  }
                }
              }}
            >
              立即参与
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
